<template>
  <!-- First Row -->
  <div>
    <b-row>
      <b-col
        cols="12"
        xl="9"
        lg="8"
        md="7"
      >
        <b-card>

          <b-row v-if="order">
            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">

                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      Order N° :                     <span class="card-text">{{ order.number }}</span>

                    </h4>
                  </div>
                  <div class="d-flex flex-wrap">

                    <b-button
                      :to="{ name: 'edit-order', params: { id: order.id } }"
                      variant="primary"
                      class="ml-1"
                    >
                      Update
                    </b-button>
                  </div>
                </div>
              </div>

              <!-- User Stats -->
              <div class="d-flex align-items-center mt-2">

                <div class="d-flex align-items-center">
                  <b-avatar
                    variant="light-info"
                    rounded
                  >
                    <feather-icon
                      icon="UserPlusIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      Supplier
                    </h5>
                    <small>{{ order.supplier.company_name }}</small>
                  </div>
                </div>

                <div class="d-flex align-items-center ml-2">
                  <b-avatar
                    v-if="order.status == 0"
                    variant="light-warning"
                    rounded
                  >
                    <feather-icon
                      icon="LoaderIcon"
                      size="18"
                    />
                  </b-avatar>
                  <b-avatar
                    v-if="order.status == 1"
                    variant="light-success"
                    rounded
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="18"
                    />
                  </b-avatar>

                  <div class="ml-1">
                    <h5 class="mb-0">
                      Status
                    </h5>
                    <b-badge
                      v-if="order.status == 0"
                      variant="warning"
                      class="badge-glow"
                    >
                      In way to Tunisia
                    </b-badge>
                    <b-badge
                      v-if="order.status == 1"
                      class="badge-glow"
                      variant="success"
                    >
                      Received in tunisia
                    </b-badge>

                  </div>
                </div>
                <div class="d-flex align-items-center ml-2">
                  <b-avatar
                    variant="light-danger"
                    rounded
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      Total cost price
                    </h5>
                    <small>{{ order.total_pa }}</small>
                  </div>
                </div>
                <div class="d-flex align-items-center ml-2">
                  <b-avatar
                    variant="light-danger"
                    rounded
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      date
                    </h5>
                    <b-badge
                      class="badge-glow"
                      variant="success"
                    >
                      {{ order.date }}
                    </b-badge>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <!-- <b-col
              cols="12"
              xl="6"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Gouvernorat</span>
                  </th>
                  <td class="pb-50">
                    -----
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="DollarSignIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Prix TTC</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    -----
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Téléphone 1</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    ----  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="FlagIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Téléphone 2</span>
                  </th>
                  <td class="pb-50">
                    -----
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="PhoneIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Dépot</span>
                  </th>
                  <td>
                    -----
                  </td>
                </tr>
              </table>
            </b-col> -->
          </b-row>
        </b-card>
      </b-col>
      <b-col
        v-if="order.is_invoiced === false || order.status === 0 "
        cols="12"
        md="5"
        xl="3"
        lg="4"
      >
        <b-card>
          <b-button
            v-if="order.is_invoiced === false && order.status === 0"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block

            @click="
              $router.push({
                name: 'create_purchase_invoices',
                params: { id: id} ,
              })
            "
          >
            Generate Purchase Invoice
          </b-button>
          <!-- Button: Print -->
          <b-button
            v-if="order.is_invoiced === true && order.status === 0"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-4"
            block
            @click="changeStatus"
          >
            Received in tunisia
          </b-button>

          <!-- Button: Edit -->
        </b-card>

      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card>
          <h4 class="mb-2">
            historic
          </h4>
          <app-timeline>
            <app-timeline-item
              v-for="item in histories"
              :key="item.id"
              :title="item.message"
              :subtitle="message(item.message)"
              :time="formatFn(item.created_at)"
              :variant="color(item.message)"
            />

          </app-timeline>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card>
          <h4 class="mb-2">
            Products
          </h4>
          <b-table
            hover
            :items="items"
            :fields="fields"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import qs from 'qs'
// eslint-disable-next-line import/no-unresolved
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    AppTimelineItem,
    AppTimeline,
    BTable,
    // Local Components

  },
  data() {
    return {
      value: 'https://example.com',
      size: 115,
      histories: [],
      order: null,
      id: '',
      fields: ['item_code', 'PA / CTN', 'quantity', 'total PA'],
      items: [

      ],
      tableVariants: [
        'none',
        'primary',
        'secondary',
        'info',
        'danger',
        'warning',
        'success',
        'light',
        'dark',
      ],
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getOrder()
    this.getHistories()
  },
  methods: {
    color(status) {
      if (status === 'en attente') {
        return 'danger'
      }
      if (status === 'picked') {
        return 'warning'
      }
      if (status === 'au depot') {
        return 'info'
      }
      if (status === 'en cour') {
        return 'warning'
      }
      if (status === 'retour au depot') {
        return 'danger'
      }
      if (status === 'transfert') {
        return 'info'
      }
      return 'success'
    },
    message(status) {
      if (status === 'Order created') {
        return 'Order in way to Tunisia '
      }

      return ''
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async changeStatus() {
      this.$swal({
        title: 'Are you sure to validate this order?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'yes ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.post(
              '/receive-in-tunisia/',
              { order: this.order.id, products: this.order.products },
            )
              .then(() => {
                setTimeout(() => {
                  this.showToast('success', 'top-center', 'Order received in tunisia')
                }, 1000)
                this.refresh()
              })
              .catch(error => {
                setTimeout(() => {
                  this.showToast('danger', 'top-center', error.toString())
                }, 1000)
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Close',
              text: 'Order in way to tunisia',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    async getHistories() {
      this.$http.get('/histories/', {
        params: { order: this.$route.params.id },
      }).then(response => {
        this.histories = response.data
      })
    },
    // eslint-disable-next-line camelcase
    addnewitem(pa_ctn, qte, item_code, total_pa) {
      this.items.push({
        item_code,
        'PA / CTN': pa_ctn,
        quantity: qte,
        'total PA': total_pa,
      })
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    async getOrder() {
      await this.$http.get(`/orders/detail/${this.$route.params.id}/`).then(response => {
        this.order = response.data
      })
      if (this.order != null) {
        this.$http.post(
          '/detail-products-order/',
          { order: this.order.id, products: this.order.products },
        ).then(res => {
          const result = res.data
          for (let i = 0; i < result.length; i += 1) {
            const qte = result[i].quantity
            const { pa_ctn } = result[i]
            const { total_pa } = result[i]
            this.addnewitem(pa_ctn, qte, result[i].product, total_pa)
            this.somme += Number(total_pa)
          }
        })
      }
    },
    async refresh() {
      await this.$http.get(`/orders/detail/${this.$route.params.id}`).then(response => {
        this.order = response.data
      })
    },

  },

}
</script>
